import React, { useState } from 'react';
import { useLocation} from 'react-router-dom'; // Hook para acessar a localização
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import BannerLogo from '../../../components/bannerlogo/BannerLogo';
import './Checkout.css';

const CheckoutForm = ({ course }) => {

    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        console.log("Checking email", email);
        event.preventDefault();
        setMessage('');

        if (!stripe || !elements) {
            console.log("Stripe or elements not loaded");
            return;
        }

        if (!isEmailChecked) {
            if (!validateEmailFormat(email)) {
                setMessage('Invalid email format.');
                return;
            }

            setIsLoading(true);
            try {
                // Verifique se os campos do curso estão vazios
                if (course.name === '' || course.price === '' || course.id === '') {
                    throw new Error('Course data is empty');
                }

                const course_id_string = course.id.toString();
                const course_price_string = course.price.toString();

                // Obtém o token de sessão do cookie (ou de outra origem se necessário)
                const cookie = document.cookie.split('; ').find(row => row.startsWith('session='));
                const sessionToken = cookie ? cookie.split('=')[1] : null;

                if (!sessionToken) {
                    throw new Error('Session token not found');
                }

                let user_id;
                try {
                    const tokenPayload = JSON.parse(atob(sessionToken.split('.')[1])); // Decodifica o payload do JWT
                    user_id = tokenPayload.user_id; // Extrai o user_id do payload
                } catch (error) {
                    throw new Error('Invalid session token format');
                }

                if (!user_id) {
                    throw new Error('User ID not found in session token');
                }

                const response = await fetch('https://api.lideratech.com.br/api/v1/create-payment-intent', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: email, // O email também pode ser extraído do token
                        course_id: course_id_string,
                        course_name: course.name,
                        price: course_price_string,
                        user_id: user_id
                    }),
                });

                if (!response.ok) {
                    console.log("Error response", response);
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Failed to create payment intent.');
                }

                console.log("Course set to request", course);
                const { clientSecret } = await response.json();
                setClientSecret(clientSecret);
                setIsEmailChecked(true);
            } catch (error) {
                setMessage("Erro ao checar os dados, tente mais tarde ou nos envie um email contato@lideratech.com.br:" + error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(true);
            try {
                const cardElement = elements.getElement(CardElement);
                const { error } = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: { email },
                    },
                });

                if (error) {
                    throw new Error(error.message);
                }

                setMessage('Payment successful!');
                document.getElementById('payment-form').classList.add('hidden');
                document.getElementById('payment-message-success').classList.remove('hidden');
                setTimeout(() => {
                    window.location.href = '/dashboard';
                }, 3000);
            } catch (error) {
                setMessage(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const validateEmailFormat = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        variant="outlined"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isEmailChecked}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Value"
                        name="price"
                        type="text"
                        variant="outlined"
                        value={course.price}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Course Name"
                        name="course_name"
                        type="text"
                        variant="outlined"
                        value={course.name}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Course ID"
                        name="course_id"
                        type="text"
                        variant="outlined"
                        value={course.id}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                {isEmailChecked && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Payment Details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Card Holder"
                                name="card_holder"
                                type="text"
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': { color: '#aab7c4' },
                                            },
                                            invalid: { color: '#9e2146' },
                                        },
                                        hidePostalCode: true,
                                    }}
                                />
                            </Paper>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <div className="spinner"></div>
                        ) : (
                            <span>{isEmailChecked ? 'Pay' : 'Check'}</span>
                        )}
                    </Button>
                </Grid>
                {message && (
                    <Grid item xs={12}>
                        <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-danger'}`}>
                            {message}
                        </div>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

const Checkout = () => {

    const stripePromise = loadStripe('pk_live_51OWqbkGtR5GnVnvl5soWquxEJyWTfIToxgFVR5F4OhIwThy5DmLi3vTSVC8AwQPJJ9wXcYOb3iZqIWB6PhOiWB2J00Kl76PGW2');

    const location = useLocation(); // Hook para acessar a localização
    const { course } = location.state || {}; // Obter os dados do curso da localização

    if (!course) {
        console.error('Course not found');
        return (
            <>
                <Header />
                <Typography variant="h6" component="h6" align="center" sx={{ mt: 4 }}>
                    Produto não encontrado!
                </Typography>

                {/*// open https://www.lideratech.com.br/dashboad*/}
                {/*button color green*/}
                <Button variant="contained" color="primary" href="/dashboard" sx={{ mt: 2 }}
                        style={{ color: 'white', backgroundColor: 'green' }}>
                    Voltar
                </Button>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <div className="checkout" id="payment-form">
                <h1>Checkout</h1>
                <p>Confira os dados antes de seguir com o pagamento.</p>
                <Paper sx={{p: 10, margin: 'auto', maxWidth: 1000}}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Elements stripe={stripePromise}>
                                <CheckoutForm course={course} />
                            </Elements>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            <div id="payment-message-success" className="hidden">
                <h1>Pagamento realizado!</h1>
                <p>Você será redirecionado...</p>
            </div>
            <BannerLogo />
            <Footer />
        </>
    );
};

export default Checkout;
