import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

export default function Content() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h3"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Tecnologia evoluindo
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Acompanhar tudo é impossível, então filtramos o que faz sentido para nós e vamos compartilhar com vocês.
            </Typography>
          </Container>
        </Box>
      </main>
    </ThemeProvider>
  );
}